import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="feed"
export default class extends Controller {
  static values = { searching: Boolean };
  private searchingValue: boolean;
  private timeout: number | null;

  connect() {
    if (this.searchingValue) this.reload();
  }

  refill() {
    if (this.element.childElementCount <= 1) {
      location.reload();
    }
  }

  reload() {
    this.timeout = window.setTimeout(() => {
      location.reload();
    }, 5000);
  }

  disconnect() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
      this.timeout = null;
    }
  }
}
