import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="search"
export default class extends Controller {
  static targets = ["submit", "next", "q"];
  static values = { q: String };

  private submitTarget: HTMLButtonElement;
  private nextTarget: HTMLButtonElement;
  private hasNextTarget: boolean;
  private qTarget: HTMLInputElement;
  private qValue: string;

  connect() {
    this.change();
  }

  change() {
    const qChanged = this.qValue !== this.qTarget.value;

    this.submitTarget.disabled = !qChanged;
    if (this.hasNextTarget) {
      this.nextTarget.disabled = qChanged;
    }
  }

  loading() {
    this.submitTarget.disabled = true;
    if (this.hasNextTarget) this.nextTarget.disabled = true;
  }
}
