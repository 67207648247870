import { Controller } from "@hotwired/stimulus";
import { scaleLinear, scaleTime, select, axisBottom, axisLeft } from "d3";

// Connects to data-controller="histogram"
export default class extends Controller {
  static targets = ["chart"];
  static values = { data: Object };

  private chartTarget: SVGAElement;
  private dataValue: {
    expected_results: number;
    bins: { bin: string; count: number }[];
  };

  connect() {
    const marginLeft = 100;
    const marginBottom = 20;
    const height = viewPortY(this.chartTarget) - marginBottom;
    const width = viewPortX(this.chartTarget) - marginLeft;
    const yMax = Math.max(...this.dataValue.bins.map(({ count }) => count));

    const unixTimestamps = this.dataValue.bins.map(({ bin }) =>
      Date.parse(bin).valueOf()
    );

    const xMax = Math.max(...unixTimestamps);
    const xMin = Math.min(...unixTimestamps);

    const x = scaleTime().domain([xMin, xMax]).range([marginLeft, width]);

    const y = scaleLinear().domain([yMax, 0]).range([0, height]);

    console.log(unixTimestamps.length);

    select(this.chartTarget)
      .append("g")
      .attr("transform", `translate(${marginLeft},0)`)
      .call(axisLeft(y).ticks(height / 100));

    select(this.chartTarget)
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(axisBottom(x).ticks(width / 100));

    select(this.chartTarget)
      .append("g")
      .attr("fill", "white")
      .selectAll("rect")
      .data(unixTimestamps)
      .join("rect")
      .attr("x", (d) => x(d))
      .attr("width", width / this.dataValue.expected_results - 4)
      .attr("y", (d, i) => y(this.dataValue.bins[i].count))
      .attr("height", (d, i) => height - y(this.dataValue.bins[i].count));
  }
}

const viewPortY = (svg: SVGAElement): number => {
  return Number.parseInt(svg.attributes["viewBox"].nodeValue.split(",")[3]);
};

const viewPortX = (svg: SVGAElement): number => {
  return Number.parseInt(svg.attributes["viewBox"].nodeValue.split(",")[2]);
};
