import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="clipboard"
export default class extends Controller {
  static values = { copy: String };
  static targets = ["default", "copied"];
  private copyValue: string;

  private defaultTarget: SVGElement;
  private copiedTarget: SVGElement;

  connect() {}

  copy() {
    navigator.clipboard.writeText(this.copyValue);
    this.defaultTarget.classList.add("hidden");
    this.copiedTarget.classList.remove("hidden");
    setTimeout(() => {
      this.defaultTarget.classList.remove("hidden");
      this.copiedTarget.classList.add("hidden");
    }, 500);
  }
}
